//import { Tooltip } from 'bootstrap'
import React from 'react'
import {Link} from 'react-router-dom'
import ContactSales from '../Company/contactSales'
import {NavLink, useNavigate } from 'react-router-dom'

function Product2() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 inner-bg text-white'>                
                </div>
            </div>
        </div>
        <section className="breadcrumbs">
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>Products</h2>
                
                </div>

            </div>
        </section>

        <div className='container-fluid  pb-5'>
            <div className='row'>
                <div className='col-md-12 pt-4 pb-4'>
                    <div className='container'>
                            <div className='row'>
                                <div className='col-md-6 p-3'>
                                    <div className="card" >
                                    <img className="card-img-top" src="./images/product/sipc-product-img-1.png" alt="Card image cap" style={{"height":"200px"}} />
                                        <div className="card-body productbox-body">
                                            <h5 className="card-title">iNoCulator<sup className='tm'>TM</sup></h5>
                                            <p className="card-text">A cloud-based EDA Tool</p>
                                            <NavLink to="/INOCulator" className="btn btn-secondary">Learn More</NavLink>
                                            <a href="https://inoculator.ai/request-access" className="btn btn-primary float-right" target='_blank'><i className='fa fa-external-link-alt me-1'></i>Start Eval</a>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6 p-3'>
                                    <div className="card" >
                                    <img className="card-img-top" src="./images/product/sipc-product-img-2.png" alt="Card image cap" style={{"height":"200px"}} />
                                        <div className="card-body productbox-body">
                                            <h5 className="card-title">Noncoherent-NoC</h5>
                                            <p className="card-text">A layered, scalable, physically aware configurable NoC</p>
                                            <NavLink to="/NCNoc" className="btn btn-secondary">Learn More</NavLink>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6 p-3'>
                                    <div className="card" >
                                    <img className="card-img-top" src="./images/product/sipc-product-img-2.png" alt="Card image cap" style={{"height":"200px"}} />
                                        <div className="card-body productbox-body">
                                            <h5 className="card-title">Coherent-NoC</h5>
                                            <p className="card-text">A layered, scalable, configurable, and physically aware configurable NoC</p>
                                            <NavLink to="/CNoC" className="btn btn-secondary">Learn More</NavLink>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6 p-3'>
                                    <div className="card" >
                                    <img className="card-img-top" src="./images/product/sipc-product-img-4.png" alt="Card image cap" style={{"height":"200px"}} />
                                        <div className="card-body productbox-body">
                                            <h5 className="card-title">PCIe Gen6 Controller</h5>
                                            <p className="card-text">"NoC aware” PCIe gen 6 controller IP for efficient data transfer up to 64 GT/s</p>
                                            <NavLink to="/PCIe" className="btn btn-secondary">Learn More</NavLink>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 p-3'>
                                    <div className="card" >
                                    <img className="card-img-top" src="./images/product/hps.png" alt="Card image cap" style={{"height":"200px"}} />
                                        <div className="card-body productbox-body">
                                            <h5 className="card-title">Scalable HPS-based Subsystem</h5>
                                            <p className="card-text">Embedded Scalable HPS-based Subsystem with Network-on-Chip for Intel Agilex 7 SOC FPGA</p>
                                            <NavLink to="/HPS" className="btn btn-secondary">Learn More</NavLink>
                                            
                                        </div>
                                    </div>
                                </div>

                                {/*<div className='col-md-4 p-3'>
                                    <div className="card" >
                                    <img className="card-img-top" src="./images/AIaccelerator-01.png" alt="Card image cap" style={{"height":"200px"}} />
                                        <div className="card-body productbox-body">
                                            <h5 className="card-title">AI Accelerator</h5>
                                            <p className="card-text">NovuTensor Architecture-based AI accelerator</p>
                                            <NavLink to="/AIAccelerator" className="btn btn-primary">Learn More</NavLink>
                                            
                                        </div>
                                    </div>
  </div>*/}
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <ContactSales productName={"iNoCulator"} />
    </>
  )
}

export default Product2